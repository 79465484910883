table.primary {
  thead {
    tr {
      height: 40px;
      border-bottom: 1px solid #848689;
      th {
        color: var(--fonts-gray);
        padding: 0;
        font-weight: 800;
        font-size: 0.75rem;
      }
    }
  }
  tbody {
    tr {
      height: 40px;
      border-bottom: 1px solid #848689;
      td {
        color: var(--fonts-primary);
        font-weight: 500;
        font-size: 0.75rem;
        padding: 0;
      }
    }
  }
}
