.course-overview-tab {
  .MuiGrid-item {
    padding: 0 1px;
  }
  .course-stats-card {
    height: 130px;
    background: #233548;
    color: #fff;
    padding: 16px;
    display: flex;
    box-shadow: none;
    flex-direction: column;
    justify-content: space-between;
    .top-blk {
      display: flex;
      justify-content: space-between;
      span {
        &:before {
          font-size: 2.3rem;
        }
      }
    }
    h2 {
      font-size: 1.1rem;
      font-weight: bold;
    }
    h3 {
      font-size: 2.2rem;
      font-weight: bold;
    }
  }
}

.main-wrapper .card-with-label {
  padding: 30px 24px;
  background: var(--white);
  border: 1px solid rgba(199, 224, 253, 0.43);
  border-radius: 9px;
  box-shadow: none;
  .graph-title {
    color: #414b5f;
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 32px;
  }
  &.highlight {
    border: 2px solid rgba(121, 178, 243, 0.43);
    box-shadow: 4px 1px 24px 4px rgba(239, 239, 239, 1);
  }
}
