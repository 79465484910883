.assignment-preview {
  .preview-students {
    padding-bottom: 50px;
    h2 {
      font-size: 1rem;
      font-weight: 500;
      color: var(--fonts-primary);
      margin-bottom: 10px;
    }
    p {
      font-size: 0.85rem;
      font-weight: 400;
      color: #414b5f;
    }
  }
  .assignment-preview-top-blk {
    h2 {
      font-size: 1rem;
      font-weight: bold;
      color: var(--fonts-primary);
      margin-bottom: 10px;
    }

    span.MuiTypography-caption {
      font-size: 0.75rem;
      font-weight: 400;
      color: var(--fonts-gray);
    }

    h3 {
      font-size: 0.85rem;
      font-weight: 500;
      color: var(--fonts-gray);
    }
  }

  .instruction-blk {
    background: rgba(231, 240, 250, 0.5);
    border-radius: 6px;
    padding: 20px 20px 40px 20px;
    margin-bottom: 20px;
    .top-blk {
      h2 {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--fonts-primary);
        margin-bottom: 10px;
      }
      p {
        font-size: 0.85rem;
        color: #414b5f;
        margin-bottom: 25px;
      }
    }
  }
}
.question-blk.primary-blk {
  background: rgba(254, 254, 255, 0.08);
  border: 1px solid rgba(65, 75, 95, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 30px;
  .num-blk {
    width: 120px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    background: #414b5f;
    border-top-left-radius: 4px;
  }
  .marks {
    font-size: 0.85rem;
    color: #414b5f;
    font-weight: 400;
  }
  .options-blk {
    padding: 20px;
    .option {
      margin-bottom: 20px;
      align-items: baseline;
      &:last-of-type {
        margin-bottom: 0;
      }
      .selected-option {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        margin-right: 20px;
        border-radius: 50%;
        &.active {
          border: 2px solid #414b5f;
          align-items: center;
        }
        span {
          color: #414b5f;
          font-size: 1rem;
        }
        .correct-selection {
          width: 40px;
          height: 40px;
          display: flex;
          border-radius: 50%;
          color: #fff;
          align-items: center;
          justify-content: center;
          background-color: #58a785;
        }
        .incorrect-selection {
          width: 40px;
          height: 40px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: #de0000;
          color: #fff;
        }
      }
      h3 {
        font-size: 1rem;
        color: var(--fonts-primary);
        line-height: normal;
        font-weight: 400;
        &.active {
          color: var(--fonts-primary);
        }
      }
    }
    .descriptive {
      display: flex;
      // justify-content: space-between;
      p {
        color: #788093;
        font-size: 1rem;
        line-height: 24px;
      }
      span {
        color: var(--fonts-primary);
        display: block;
        font-size: 1rem;
        margin-right: 24px;
      }
    }
  }
}
.grade-assignment-blk {
  .top-blk h2 {
    color: var(--heading-main);
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 26px;
  }
  .question-blk.primary-blk {
    .num-blk {
      background: transparent;
      color: var(--heading-main);
      font-size: 1rem;
      font-weight: bold;
    }
    .opted-marks {
      border: 1px solid #e7e7e7;
      border-radius: 2px;
      padding: 6px 8px 3px 6px;
      background: #fff;
      margin-right: 16px;
      display: flex;
      align-items: center;
      margin: 16px 16px 0 0;
      h3 {
        font-size: 0.85rem;
        font-weight: 500;
        color: var(--fonts-primary);
      }
      input {
        padding: 0;
        text-align: center;
        width: 40px;
        font-size: 0.85rem;
      }
      fieldset {
        border: 0;
      }
    }
    .comment-area {
      padding: 8px 20px;
      flex-direction: column;

      h6 {
        margin: 8px 0;
        font-weight: 500;
        font-size: 0.85rem;
        color: var(--fonts-primary);
      }
      .MuiFormControl-root {
        width: 100%;

        .MuiInputBase-root {
          background: #fff;

          &.Mui-disabled {
            background: #d5d5d5;
          }
          fieldset {
            border: 1px solid #e9e9e9;
          }
          textarea {
            padding: 0;
            margin-top: 4px;
            font-size: 0.85rem;

            &::placeholder {
              opacity: 1;
              font-size: 0.85rem;
              color: var(--fonts-gray);
            }
          }
        }
      }
    }
  }
  .assessment-comment-area {
    flex-direction: column;
    margin: 32px 0;

    h6 {
      margin: 8px 0;
      font-weight: 600;
      font-size: 0.85rem;
      color: var(--fonts-primary);
    }
    .MuiFormControl-root {
      width: 100%;

      .MuiInputBase-root {
        background: #fff;

        &.Mui-disabled {
          background: #d5d5d5;
        }
        fieldset {
          border: 1px solid #e9e9e9;
        }
        textarea {
          padding: 0;
          margin-top: 4px;
          font-size: 0.85rem;

          &::placeholder {
            opacity: 1;
            font-size: 0.85rem;
            color: var(--fonts-gray);
          }
        }
      }
    }
  }
}
