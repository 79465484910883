.student-overview-tab {
  .exams-stats-card {
    background: #ffffff;
    border: 1px solid rgba(199, 224, 253, 0.43);
    border-radius: 9px;
    padding: 20px;
    .graph-title {
      color: var(--fonts-primary);
      font-size: 1.1rem;
      font-weight: 800;
      margin-bottom: 10px;
    }
    .top-blk {
      .icon-blk {
        > div {
          font-size: 3rem;
          &:before {
            color: var(--heading-main);
          }
        }
      }
    }
    .info {
      h2 {
        color: #1c8c7d;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 5px;
      }
      h3 {
        color: var(--fonts-primary);
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 20px;
        span {
          color: #1c8c7d;
          font-weight: bold;
          font-size: 0.4rem;
        }
      }
      h4 {
        color: var(--fonts-primary);
        font-size: 1.1rem;
        margin-bottom: 5px;
        font-weight: bold;
      }
    }
  }
  .detail-box {
    background: #ffffff;
    border: 1px solid rgba(94, 94, 94, 0.2);
    border-radius: 3px;
    padding: 20px;
    .MuiAvatar-root {
      width: 80px;
      height: 80px;
      border: 1px solid #cbcbcb;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}
