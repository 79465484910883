.dashboard {
  .banner-blk {
    background: #414b5f;
    padding: 30px;
    margin-bottom: 36px;
    border-radius: 8px;
    h2 {
      color: var(--fonts-white);
      font-size: 1.4rem;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      color: var(--fonts-white);
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 2rem;
      span {
        color: #feb036;
      }
    }
    .img-blk {
      display: flex;
      justify-content: flex-end;
      img {
        display: block;
      }
    }
  }
  .dashboard-card {
    background: #ffffff;
    border: 1px solid rgba(199, 224, 253, 0.43);
    border-radius: 9px;
    padding: 20px 12px 16px 12px;
    box-shadow: none;
    .top-blk {
      margin-bottom: 10px;
      h2 {
        color: var(--fonts-primary);
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 1.6rem;
        max-width: 110px;
      }
    }
    .info {
      h2 {
        color: var(--fonts-golden);
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 5px;
      }
      h3 {
        color: var(--fonts-primary);
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 5px;
        span {
          color: #1c8c7d;
          font-weight: bold;
          font-size: 0.4rem;
        }
      }
      p {
        color: var(--fonts-gray);
        font-size: 0.7rem;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .subject-scores {
      margin-top: 10px;
      h2 {
        color: var(--fonts-dark);
        font-size: 0.85rem;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .score {
        border-bottom: 1px solid #78809333;
        padding: 12px 0;
        &:last-of-type {
          padding-bottom: 0;
          border: 0;
        }
        h3 {
          color: var(--fonts-secondary);
          font-size: 0.7rem;
          font-weight: 500;
        }
        h4 {
          color: var(--fonts-secondary);
          font-size: 0.7rem;
          font-weight: bold;
          min-width: 36px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-weight: bold;
            font-size: 0.4rem;
            display: inline-block;
            margin-left: 5px;
          }
        }
        .up {
          color: #1c8c7d;
        }
        .down {
          color: #e8290e;
        }
      }

      .accordion:before {
        background-color: transparent !important;
      }

      .accordion-label {
        height: 38.8px !important;
        min-height: 38.8px !important;

        div {
          justify-content: space-between;
        }
      }

      .accordion-details {
        background-color: #f9f9f9;
        min-height: 38.8px !important;
        padding: 12px 0 12px 6px !important;
      }
    }
  }
}
.event-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15px;
  margin: 20px 0;
  position: relative;
  &.mid {
    &::before {
      border-color: #e8c842;
    }
  }
  &.other {
    &::before {
      border-color: #ff7800;
    }
  }
  &::before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    border-left: 2px solid #90b0d7;
  }
  .left-blk {
    h2 {
      color: var(--fonts-primary);
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 8px;
    }
    h3 {
      color: var(--fonts-gray);
      font-size: 0.75rem;
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom: 0;
      }
      span {
        color: var(--fonts-primary);
        &.icon-students1 {
          color: var(--fonts-gray);
        }
      }
    }
  }
  .right-blk {
    display: flex;
    align-items: center;
    margin-top: 26px;
    color: var(--fonts-gray);
    h3 {
      font-size: 0.75rem;
    }
  }
}
.events-wrap {
  padding: 24px;
  background: #fff;
  border: 1px solid rgba(199, 224, 253, 0.43);
  border-radius: 8px;
  height: 100%;
  > h2 {
    color: var(--fonts-primary);
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .events-blk {
    height: 555px;
    padding-right: 5px;
    overflow: auto;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      transition: all ease 0.3s;
    }
    &:hover::-webkit-scrollbar {
      background-color: #dcdcdc;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
    }
  }
  .event {
    .date {
      color: var(--fonts-primary);
      font-weight: 400;
      font-size: 0.65rem;
      padding-bottom: 5px;
      border-bottom: 1px solid rgb(144 176 212 / 30%);
      display: block;
    }
    .event-detail {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 15px;
      margin: 20px 0;
      position: relative;
      &::before {
        border-color: #ff7800;
        border-left: 2px solid #ff7800;
      }
      &.Exam, 
      &.Sınav, 
      // &.'Kısa Sınav',
      &.Quiz {
        &::before {
          border-color: #e8c842;
          border-left: 2px solid #e8c842 !important;
        }
      }
      &.Ödev::before {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        content: "";
        border-left: 2px solid #90b0d7;
      }
      &.Assignment::before {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        content: "";
        border-left: 2px solid #90b0d7;
      }
      // &.mid {
      //   &::before {
      //     border-color: #e8c842;
      //   }
      // }
      // &.other {
      //   &::before {
      //     border-color: #ff7800;
      //   }
      // }
      // &::before {
      //   position: absolute;
      //   height: 100%;
      //   left: 0;
      //   top: 0;
      //   content: "";
      //   border-left: 2px solid #90b0d7;
      // }
      .left-blk {
        h2 {
          color: var(--fonts-primary);
          font-size: 1rem;
          font-weight: bold;
          margin-bottom: 8px;
        }
        h3 {
          color: var(--fonts-gray);
          font-size: 0.75rem;
          margin-bottom: 8px;
          &:last-of-type {
            margin-bottom: 0;
          }
          span {
            color: var(--fonts-primary);
            &.icon-students1 {
              color: var(--fonts-gray);
            }
          }
        }
      }
      .right-blk {
        display: flex;
        align-items: center;
        margin-top: 26px;
        color: var(--fonts-gray);
        h3 {
          font-size: 0.75rem;
        }
      }
    }
  }
}
