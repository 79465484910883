.gpt-question-wrap {
  background: var(--white);
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 16px 16px 32px 32px;
  margin-bottom: 16px;
  .count {
    color: var(--fonts-gray);
    margin-right: 26px;
    font-size: 1rem;
    font-weight: bold;
    span {
      font-size: 0.65rem;
      line-height: 20px;
    }
  }
  h2 {
    font-size: 1rem;
    color: #233648;
  }
  .gpt-mcq-options {
    margin-top: 40px;
  }
}
.fullscreen-gpt-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
}
