.category-wrap {
  > ul {
    margin-top: 15px;
    ul {
      background: rgba(219, 235, 255, 0.16);
      .icon-square {
        color: #f77320;
      }
      ul {
        background: rgba(219, 235, 255, 0.3);
        .icon-square {
          color: #7146f7;
        }
        ul {
          background: rgba(219, 235, 255, 0.5);
          .icon-square-wrap {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              width: 5px;
              height: 5px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -60%);
              background: #ffffff;
              border-radius: 50%;
            }
          }
          .icon-square {
            color: #f00af5;
          }
        }
      }
    }
    li {
      ul {
        padding: 20px 10px 20px 35px;
      }
      .tree-item-content {
        padding: 0 10px;
        cursor: pointer;
        background: transparent;
        height: 42px;
        transition: all ease 0.4s;
        border: 1px solid transparent;
        & + ul {
          transition: all ease 1s;
          opacity: 0;
          max-height: 0;
        }
        &[data-open="true"] {
          & + ul {
            opacity: 1;
            overflow: auto;
            max-height: 600px;
          }
          .right-blk {
            .tree-actions {
              .arrow {
                transform: rotate(180deg);
              }
            }
          }
        }
        &:hover {
          box-shadow: 0px 0px 9px rgb(118 118 118 / 11%);
          border-radius: 2px;
          border: 1px solid rgba(231, 240, 250, 0.96);
          background: #fff;
          .right-blk {
            padding-right: 70px;
            &.three {
              padding-right: 100px;
            }
            .tree-actions {
              opacity: 1;
              transform: translateY(0px);
            }
          }
        }
        p.MuiTypography-root {
          color: var(--fonts-primary);
          font-size: 0.85rem;
        }
        .icon-square-wrap {
          margin-right: 10px;
          svg {
            width: 15px;
            height: 15px;
          }
        }
        .right-blk {
          display: flex;
          align-items: center;
          position: relative;
          transition: all ease 0.4s;
          padding-right: 0;
          .MuiTypography-root {
            font-size: 0.7rem;
          }
          .tree-actions {
            position: absolute;
            transform: translateY(10px);
            right: 0;
            display: flex;
            opacity: 0;
            transition: all ease 0.3s;
            svg {
              &:last-of-type {
                margin-right: 0;
              }
            }
            &.lastChildActions {
              svg {
                font-size: 1.2rem;
              }
            }
            .arrow {
              transition: all ease 0.3s;
            }
          }
        }
      }
    }
  }
  #category-btm-sheet {
    position: sticky;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    transition: all ease 0.7s;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 4px 1px 24px 4px rgba(239, 239, 239, 1);
    mix-blend-mode: normal;
    backdrop-filter: blur(6px);
    &.active-blk {
      overflow: visible;
      opacity: 1;
      // max-height: 300px;
      max-height: 500px;
    }
    .category-btm-sheet-wrap {
      padding: 30px 15px;
      .level-icons {
        button {
          svg {
            font-size: 1rem;
            color: var(--fonts-primary);
          }
        }
      }
      .top-blk {
        .caption {
          margin-bottom: 0;
        }
        .MuiIconButton-root {
          padding: 0;
          svg {
            width: 15px;
            height: 15px;
            color: black;
          }
        }
      }
      .btm-blk {
        justify-content: space-between;
        align-items: center;
        .MuiBox-root {
          flex-grow: 1;
          &:not(:last-of-type) {
            padding-right: 10px;
            border-right: 1px solid #dadee5;
            margin: 30px 20px 0 0;
          }
          &:last-of-type {
            max-width: 50%;
            width: 50%;
          }
        }
        .MuiOutlinedInput-root {
          width: 100%;
          .MuiSelect-nativeInput + svg + fieldset {
            border: 0;
          }

          .MuiSelect-select {
            font-size: 0.85rem;
            color: var(--fonts-primary);
            padding: 0;
          }
        }
      }
    }
  }
}
